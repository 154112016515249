import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0,6,7];

export const dictionary = {
		"/": [11],
		"/(common)/about": [12,[2]],
		"/(common)/auth/sign-in": [~13,[3]],
		"/(common)/auth/sign-up": [~14,[3]],
		"/(common)/auth/verify-email": [15,[3]],
		"/(content)/blog": [~24,[5]],
		"/(content)/blog/category/[category]": [~26,[5]],
		"/(content)/blog/[slug]": [~25,[5]],
		"/(common)/contact": [~16,[2]],
		"/(common)/discover-preview/[slug]": [~17],
		"/(protected)/discover": [~27,[6]],
		"/(protected)/discover/property/[slug]": [~28,[6,7]],
		"/(protected)/discover/property/[slug]/pictures": [29,[6,7]],
		"/(common)/faq": [18,[2]],
		"/(protected)/kyb/create-business": [~30,[8]],
		"/(protected)/kyb/create-user": [~31,[8]],
		"/(protected)/kyb/resend": [~32,[8]],
		"/(protected)/kyb/send-kyb": [~33,[8]],
		"/(protected)/kyc": [~34],
		"/(protected)/kyc/resend": [~35],
		"/(common)/landing/[...catchall]": [~19,[2]],
		"/(common)/learn-more": [20,[2]],
		"/(common)/legal/[content]": [21,[2,4]],
		"/(protected)/my-homes": [~36,[6]],
		"/(protected)/order/[orderHashId]": [~37,[6,9]],
		"/(protected)/order/[orderHashId]/check-conditions": [~38,[6,9]],
		"/(protected)/order/[orderHashId]/signature": [~39,[6,9]],
		"/(protected)/portfolio": [~40,[6]],
		"/(common)/program/loyalty": [22,[2]],
		"/(common)/program/referral": [23,[2]],
		"/(protected)/set-phone": [~41],
		"/(protected)/settings": [~42,[6,10]],
		"/(protected)/settings/account": [~43,[6,10]],
		"/(protected)/settings/notifications": [~44,[6,10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';